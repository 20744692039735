import * as React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { FaDownload } from "react-icons/fa"
import Seo from "../components/Seo"
import { Zoom } from "react-reveal"

const Text = styled.p`
  font-weight: 300;
  background-color: #ffffffcc;
  border: 10px;
  border-radius: 10px;
  padding: 15px;
  margin: 15px 0;
  line-height: 1.5;
  word-break: break-word;
`

const Box = styled.div`
  background-color: #ffffffcc;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  min-width: 250px;
`

const DownloadOptions = styled.div`
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Title = styled.h4`
  margin: 15px;
  margin-bottom: 0;
`

const Caption = styled.p`
  font-size: 0.7rem;
  margin-bottom: 15px;
`

const Button = styled.a`
  width: 100%;
  background-color: ${({ disabled }) => (disabled ? "#cccccc" : "#1c365e")};
  padding: 7px 10px;
  color: #fff;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
  text-decoration: none;
  text-align: center;
  font-size: 0.8rem;

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

const DownloadPage = () => (
  <Layout>
    <Seo title="Download" />
    <h1 className="head">Download FORSINT Desktop Application</h1>

    <br />
    <h1 className="sub-head">
      Choose the installation package that suits your environment the best:
    </h1>
    <br />
    <br />

    <DownloadOptions>
      <Zoom>
        <Box>
          <StaticImage src="../images/windows.png" width={80} alt="Windows" />
          <Title>Forsint for Windows</Title>
          <Caption>Requires Windows 10 or 11</Caption>
          <Button
            disabled={!process.env.GATSBY_WINDOWS_DOWNLOAD_BUTTON_URL}
            href={
              process.env.GATSBY_WINDOWS_DOWNLOAD_BUTTON_URL
                ? process.env.GATSBY_WINDOWS_DOWNLOAD_BUTTON_URL
                : "#"
            }
          >
            {process.env.GATSBY_WINDOWS_DOWNLOAD_BUTTON_URL ? (
              <>
                <FaDownload /> Download For Windows
              </>
            ) : (
              "Coming Soon"
            )}
          </Button>
        </Box>
      </Zoom>
      <Zoom>
        <Box>
          <StaticImage src="../images/linux.png" width={80} alt="Linux" />
          <Title>Forsint for Linux</Title>
          <Caption>Requires Ubuntu 20.04</Caption>
          <Button
            disabled={!process.env.GATSBY_LINUX_DOWNLOAD_BUTTON_URL}
            href={
              process.env.GATSBY_LINUX_DOWNLOAD_BUTTON_URL
                ? process.env.GATSBY_LINUX_DOWNLOAD_BUTTON_URL
                : "#"
            }
          >
            {process.env.GATSBY_LINUX_DOWNLOAD_BUTTON_URL ? (
              <>
                <FaDownload /> Download For Linux
              </>
            ) : (
              "Coming Soon"
            )}
          </Button>
        </Box>
      </Zoom>
      <Zoom>
        <Box>
          <StaticImage src="../images/mac.png" width={80} alt="Mac" />
          <Title>Forsint for Mac</Title>
          <Caption>Requires Apple Silicon</Caption>
          <Button
            disabled={!process.env.GATSBY_MAC_DOWNLOAD_BUTTON_URL}
            href={
              process.env.GATSBY_MAC_DOWNLOAD_BUTTON_URL
                ? process.env.GATSBY_MAC_DOWNLOAD_BUTTON_URL
                : "#"
            }
          >
            {process.env.GATSBY_MAC_DOWNLOAD_BUTTON_URL ? (
              <>
                <FaDownload /> Download For Mac
              </>
            ) : (
              "Coming Soon"
            )}
          </Button>
        </Box>
      </Zoom>
    </DownloadOptions>
    <br />
    <br />

    <h2 className="text-primary text-center">
      Do not forget to check the Hash of the Installation package with the ones
      listed below
    </h2>

    <br />
    <br />
    <br />

    <h3>Latest Version:</h3>
    <Text>
      <span className="text-bold">
        {process.env.GATSBY_CURRENT_VERSION} released{" "}
        {process.env.GATSBY_CURRENT_VERSION_RELEASE_DATE}
      </span>
      <br />
      <span className="text-bold">Windows Installer SHA256 Hash: </span>
      {process.env.GATSBY_WINDOWS_INSTALLER_SHA256}
      <br />
      <span className="text-bold">Linux Installer SHA256 Hash: </span>
      {process.env.GATSBY_LINUX_INSTALLER_SHA256}
      <br />
      <span className="text-bold">Mac Installer SHA256 Hash: </span>
      {process.env.GATSBY_MAC_INSTALLER_SHA256}
    </Text>

    <br />
    <br />
    <br />
  </Layout>
)

export default DownloadPage
